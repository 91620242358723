import React, { useContext, useState } from "react";
import Lottie from "lottie-react";
import GiftAnimation from "../../lottie/flowerFooter.json";
import { themeContext } from "../../Context";
import FormComments from "../Form/Form.Comments";

const Close = () => {
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;
  // component
  const component = (
    <div className="awesome">
      <span style={{ color: darkMode ? "white" : "", fontSize: "1.5rem" }}>
        Thank you
      </span>
      <span style={{ fontSize: "1rem" }}>Terima Kasih</span>
      <spane>
        Merupakan suatu kebahagiaan dan kehormatan bagi kami, apabila
        Bapak/Ibu/Saudara/i, berkenan hadir dan memberikan do’a restu kepada
        kami.
      </spane>

      <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
    </div>
  );
  return (
    <>
      <div className="Services" id="services">
        {" "}
        {/* Full height */}
        <div className="flex justify-center items-center h-full">
          <div>{component}</div>
        </div>
        <div className="lg:w-1/4">
          <Lottie animationData={GiftAnimation} loop={true} />
        </div>
      </div>

      <div className="h-24" />
    </>
  );
};

export default Close;
