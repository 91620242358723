import React, { useContext, useState } from "react";
import "./Services.css";
import { FaCopy } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from "./resume.pdf";
import Lottie from "lottie-react";
import GiftAnimation from "../../lottie/gift.json";
import { BankData, GiftBRIData, GiftPermataData } from "../../data/data";
import { message } from "antd";

const Gift = () => {
  const theme = useContext(themeContext);
  const [btnWeddingGift, setBtnWeddingGift] = useState(false);
  const [listTransfer, setListTransfer] = useState(false);
  const darkMode = theme?.state?.darkMode;

  const handleClickGift = (val) => {
    setBtnWeddingGift(val);
  };
  const handleClickWhatsApp = () => {
    const phoneNumber = "6282125979402";
    const message =
      "Hai, Saya mau mengkonfirmasi pengiriman kado pernikahan berupa...";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };
  // component
  const component = (
    <div className="awesome">
      <span style={{ color: darkMode ? "white" : "", fontSize: "1.5rem" }}>
        Wedding Gift
      </span>
      <span style={{ fontSize: "1rem" }}>Hadiah Pernikahan</span>
      <spane>
        Doa restu anda merupkan karunia yang sangat berarti bagi kami, dan jika
        memberi adalah ungkapan tanda kasih anda, anda dapat memberikan kado
        secara cashless.
      </spane>
      <button
        className="button s-button justify-items-center"
        onClick={() => handleClickGift(!btnWeddingGift)}
      >
        {btnWeddingGift ? "Tutup" : "Wedding Gift"}
      </button>

      <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
    </div>
  );
  const handleChangeBank = (params) => {
    if (params?.id === 1) {
      setListTransfer(GiftBRIData);
    } else {
      setListTransfer(GiftPermataData);
    }
  };
  const handleCopy = (param) => {
    if (param?.number) {
      navigator.clipboard
        .writeText(param?.number)
        .then(() => {
          message.success(`Berhasil di salin rekening ${param?.name}`);
          console.log("Tracking number copied to clipboard");
        })
        .catch((error) => {
          console.error("Error copying tracking number to clipboard:", error);
        });
    }
  };
  return (
    <div className="" id="services">
      <div className="block lg:hidden">{component}</div>
      <div className="h-5" />

      {btnWeddingGift === true && (
        <>
          <div
            className="p-4 rounded-lg shadow-lg bg-white text-[#6693b8] w-full animate-slide-fade animate-border-move lg:w-full"
            style={{
              borderColor: "#6693b8",
              borderWidth: "6px",
              borderStyle: "solid",
            }}
          >
            <div className="flex flex-row flex-wrap justify-center">
              {BankData?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center mx-3"
                  onClick={() => handleChangeBank(item)}
                >
                  <img
                    src={item?.image}
                    alt=""
                    className="cursor-pointer border-2 border-[#6693b8] p-2 rounded-lg object-cover w-full h-32" // Gambar menyesuaikan lebar kontainer
                    style={{ maxWidth: "300px" }}
                  />
                  <p className="mt-2 text-center">{item?.name}</p>
                </div>
              ))}
            </div>
            <div className="h-5" />
            {listTransfer !== false &&
              listTransfer?.map((item, index) => (
                <>
                  <div
                    className="flex flex-row justify-between border-b-2 border-[#6693b8]"
                    key={index}
                  >
                    <div>
                      <p className="text-[#6693b8] text-1xl animate-text-fade">
                        {item?.name}
                      </p>
                      <div className="flex flex-row">
                        <div>
                          <p className="text-[#6693b8] text-xs animate-text-fade">
                            {item?.number}
                          </p>
                        </div>
                        <div className="mx-1" />
                        <div>
                          <FaCopy
                            className="cursor-pointer"
                            onClick={() => handleCopy(item)}
                          />
                          <div className="h-1" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={item?.image} width={100} alt="" />
                    </div>
                  </div>
                  <div className="h-3" />
                </>
              ))}
            <div className="flex flex-row flex-wrap justify-center">
              <button className="button s-button" onClick={handleClickWhatsApp}>
                Hubungi Kami
              </button>
            </div>
          </div>
        </>
      )}
      {btnWeddingGift === false && (
        <>
          <div className="lg:w-1/4 ">
            <Lottie animationData={GiftAnimation} loop={true} />
          </div>
        </>
      )}

      <style jsx>{`
        @keyframes slideFade {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes textFade {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes borderMove {
          0% {
            transform: translateY(-10px);
          }
          50% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(-10px);
          }
        }

        .animate-slide-fade {
          animation: slideFade 1.2s ease-in-out;
        }

        .animate-text-fade {
          animation: textFade 1.5s ease-in-out;
        }

        .animate-border-move {
          animation: borderMove 2s infinite ease-in-out;
        }
      `}</style>
      <div className="h-5" />

      <div className="mx-3" />
      <div className="hidden md:block">{component}</div>
      <div className="h-24" />
    </div>
  );
};

export default Gift;
