import React, { useContext } from "react";
import "./Services.css";
import { themeContext } from "../../Context";
import Lottie from "lottie-react";
import GiftAnimation from "../../lottie/flowerFooter.json";
const Services = () => {
  const handleSaveToCalendar = () => {
    const title = "Moment Of Hasbi & Ina"; // Judul acara
    const description = `
Walimatul 'Urs Hasbi dan Ina

Dengan penuh rasa syukur kepada Allah Subhanahu wa Ta'ala, kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri dan memberikan doa restu di acara walimatul 'urs kami, Hasbi dan Ina.

Insya Allah akan diselenggarakan pada:  
📅 Hari/Tanggal: Sabtu, 28 Desember 2024  
🕰 Waktu: Pukul 08.00 WIB  
📍 Tempat: Dusun pahing RT 004 RW 003 Desa Ciawigebang, kec. Ciawigebang, kab. Kuningan

Kami berharap dengan hadirnya Bapak/Ibu/Saudara/i sekalian, dapat menambah keberkahan dan kebahagiaan bagi kedua mempelai dalam memulai kehidupan baru yang sakinah, mawaddah, dan rahmah. Semoga kehadiran dan doa yang dipanjatkan menjadikan kami pasangan yang diridhai Allah serta dilimpahi rahmat dalam setiap langkah kehidupan kami.

Catatan: Kami mengharapkan kedatangan Bapak/Ibu/Saudara/i tepat waktu dan mengenakan pakaian dengan tema warna pastel.

Jazakumullah khairan katsiran.
`;

    const location =
      "Dusun pahing RT 004 RW 003 Desa Ciawigebang, kec. Ciawigebang, kab. Kuningan"; // Lokasi acara

    // Format tanggal dan waktu
    const startDateTime = "20241228T010000Z";
    const endDateTime = "20241228T030000Z";

    // Membuat URL Google Calendar dengan parameter
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title
    )}&dates=${startDateTime}/${endDateTime}&details=${encodeURIComponent(
      description
    )}&location=${encodeURIComponent(location)}`;

    // Redirect ke Google Calendar
    window.open(googleCalendarUrl, "_blank");
  };
  // context
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;

  return (
    <div className="services" id="services">
      <div className="lg:w-1/4">
        <Lottie animationData={GiftAnimation} loop={true} />
      </div>
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "", fontSize: "1.5rem" }}>
          Wedding Ceremony
        </span>
        <span style={{ fontSize: "1rem" }}>
          Akad Nikah - Walimatul 'Urs (Resepsi)
        </span>
        <spane>
          Kami dengan penuh rasa syukur mengundang Anda untuk hadir dan
          memberikan doa restu pada acara pernikahan kami
        </spane>

        <a href="https://maps.app.goo.gl/v5Czwgo6QEx9L3eb7" target="_blank">
          <button className="button s-button">Lihat Lokasi</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      <div className="mx-3" />
      <div
        className="p-4 rounded-lg shadow-lg bg-white text-[#6693b8] w-full animate-slide-fade animate-border-move"
        style={{
          borderColor: "#6693b8",
          borderWidth: "6px",
          borderStyle: "solid",
        }}
      >
        <div className="text-center">
          <p
            className="text-[#6693b8] animate-text-fade font-bold"
            style={{ fontSize: "1rem" }}
          >
            Sabtu, 28 Desember 2024
          </p>
          <div className="h-3" />
          <p
            className="text-[#6693b8] animate-text-fade font-bold"
            style={{ fontSize: "1rem" }}
          >
            Akad Nikah
          </p>
          <p className="text-[#6693b8] text-1xl animate-text-fade">
            08.00 s.d selesai
          </p>
          <p
            className="text-[#6693b8] animate-text-fade font-bold"
            style={{ fontSize: "1rem" }}
          >
            Walimatul 'Urs (Resepsi)
          </p>
          <p className="text-[#6693b8] text-1xl border-b-2 border-[#6693b8] animate-text-fade">
            11.00 s.d selesai
          </p>
          <div className="h-3" />
          <p className="text-[#6693b8] text-xs animate-text-fade">
            “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
            pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung
            dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa
            kasih dan sayang. Sungguh, pada yang demikian itu benar-benar
            terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir”
          </p>
          <div className="h-3" />
          <p
            className="text-[#6693b8] animate-text-fade font-bold"
            style={{ fontSize: "1rem" }}
          >
            (Q.S Ar Rum : 21)
          </p>
          <button
            className="button s-button justify-items-center"
            onClick={handleSaveToCalendar}
          >
            Save Date
          </button>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideFade {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes textFade {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes borderMove {
          0% {
            transform: translateY(-10px);
          }
          50% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(-10px);
          }
        }

        .animate-slide-fade {
          animation: slideFade 1.2s ease-in-out;
        }

        .animate-text-fade {
          animation: textFade 1.5s ease-in-out;
        }

        .animate-border-move {
          animation: borderMove 2s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default Services;
